<script>
import LayoutContent from "./LayoutContent";
import ContactFooter from "./ContactFooter.vue";

export default {
  name: 'DefaultLayout',

  components: {
    LayoutContent,
    ContactFooter
  },
  
  data() {
    return {
      backgroundColor: '#FFFFFF',
    }
  },
};
</script>

<template>
  <div
    class="default-layout"
  >
    <div class="g-container">
      <LayoutContent
        :polygon="true"
        class="default-layout__header"
      />
      
      <slot class="default-layout__content"></slot>
    </div>
      <ContactFooter
        id="contact"
        ref="contact"
        class="layout-content__footer"
      />
  </div>
</template>

<style lang="scss" scoped>

.default-layout {
  width: 100%;
  margin: 0 auto 0;

  @media (max-width: $tablet-width) {
    background: #FFFFFF !important;
  }
  
  &__header {
    z-index: 1;
  }

  &__content {
    z-index: 10;
    position: absolute;
    width: 100%;
  }
}
</style>
