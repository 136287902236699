var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['textarea-container', { 'has-label': _vm.label, 'has-description': _vm.description, 'loading': _vm.loading }]},[(_vm.label)?_c('label',{class:{ 'dark' : _vm.dark }},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{class:['input-wrapper',
    {
      'input-wrapper-error': _vm.error && !_vm.dark,
      'input-wrapper-error-dark': _vm.error && _vm.dark,
      'input-wrapper-disabled': _vm.disabled,
      'input-wrapper-success': _vm.success
    }]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],attrs:{"disabled":_vm.disabled || _vm.loading,"placeholder":_vm.placeholder,"rows":_vm.rows},domProps:{"value":(_vm.inputValue)},on:{"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),(_vm.loading)?_c('AppLoader',{staticClass:"loader",attrs:{"size":24}}):_vm._e()],1),(_vm.description && !_vm.errorMessage)?_c('small',[_c('span',[_vm._v(" "+_vm._s(_vm.description)+" ")])]):_vm._e(),(_vm.errorMessage && _vm.error)?_c('small',{class:['error-message', {'dark' : _vm.dark}]},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }