<script>
import { bus } from "@/router/bus";
export default {
  name: "PersonalCabinet.vue",

  data() {
    return {
      authStatus: !!localStorage.getItem("is_auth"),
    };
  },

  computed: {
    isAuth() {
      return this.authStatus;
    },
  },

  mounted() {
    bus.$on("auth-update", () => {
      this.authStatus = !!this.$_getsetting("is_auth");
    });
  },

  methods: {
    showLoginForm(data) {
      bus.$emit("showloginform", data);
    },

    logOut() {
      this.$_removesetting("token");
      this.$_removesetting("user_id");
      this.$_removesetting("login");
      this.$_removesetting("email");
      this.$_removesetting("is_auth");
      this.$_removesetting("client_id");
      this.$_removesetting("fio");
      this.$_removesetting("clnt_name");
      this.$_removesetting("manager_email");
      this.$_removesetting("is_show_bm_messages");
      this.$_removesetting("manager_name");
      this.$_removesetting("manager_phone");
      this.$_removesetting("is_block");
      this.$_removesetting("is_added_block");
      this.$_removesetting("is_view_orders_block");
      this.$_removesetting("is_view_fin_block");

      for (let key in localStorage) {
        if (key.startsWith("request_data")) {
          this.$_removesetting(key);
        }
      }
      location.reload();
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<template>
  <div class="person-acc">
    <div class="person-acc__btns person-acc__btns--login" v-if="!isAuth">
      <button
        class="person-acc__btn"
        @click="showLoginForm('login')"
        href="!#"
        >Вход
    </button>
      <button
        class="person-acc__btn"
        @click="showLoginForm('signup')"
        href="!#"
        >Регистрация
      </button>
    </div>
    <div class="person-acc__btns person-acc__btns--authorized" v-else>
      <p class="person-acc__btn">{{ $_getsetting("login") }}</p>
      <ul class="submenu__list">
        <li class="sumbenu__list-item">
          <router-link class="submenu__list-item-link" :to="'/Cabinet'"
            >Личный кабинет</router-link
          >
        </li>
        <li class="sumbenu__list-item">
          <router-link class="submenu__list-item-link" :to="'/Profile'"
            >Профиль</router-link
          >
        </li>
        <li class="sumbenu__list-item">
          <a class="submenu__list-item-link" @click.prevent="logOut()">Выйти</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.person-acc {
  height: 25px;

  &:hover {
    .person-acc__btns--authorized {
      .submenu__list {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__btns {
    position: relative;
    display: flex;
    gap: 0 18px;

    &--authorized {
      .person-acc__btn:first-child:before {
        content: unset;
      }
    }
  }

  &__btn {
    @include font-description-semibold-0;
    position: relative;
    color: #fff;
    text-decoration: unset;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: var(--blue-30);
    }

    &:first-child {
      padding-left: 32px;

      &:before {
        position: absolute;
        content: "";
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        border-right: 2px solid #fff;
        height: 24px;
        border-radius: 1px;
      }

      &:after {
        position: absolute;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z' fill='white'/%3E%3C/svg%3E");
        width: 24px;
        height: 24px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        transition: 0.2s;
      }

      &:hover {
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z' fill='%23B2CFF1'/%3E%3C/svg%3E");
        }
      }
    }
  }
}
</style>