<script>
import LayoutContent from "./LayoutContent";

export default {
  name: 'DefaultLayout',

  components: {
    LayoutContent
  },

};
</script>

<template>
  <div class="dark-layout">
    <div class="g-container">
      <LayoutContent :polygon="true"/>
      <slot class="dark-layout__content"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dark-layout {
  background: var(--main-bg-color);
  width: 100%;
  height: 100%;
  margin: 0 auto 0;
  
  @media (max-width: $mobile-width) {
    background: #FFFFFF;
  }
  
  @media (max-width: 425px) {
    padding: 40px 16px;
  }

  &__content {
    z-index: 10;
    position: absolute;
  }
}
</style>
