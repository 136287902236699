<script>
export default {
  name: 'DefaultLayout',
  
  components: {},
};
</script>

<template>
  <div class="light-layout">
    <div class="light-layout__header">
      <img class="light-layout__header-logo" src="../../assets/img/vedexx-logo/logo_dark.svg">
    </div>
    <slot class="light-layout__content"/>
  </div >
</template>

<style lang="scss" scoped>

.light-layout {
  width: 100vw;
  max-width: 1800px;
  margin: 0 auto;
  padding: 100px 7% 100px;
  
  //background: var(--main-bg-color);
  
  @media (max-width: 600px) {
    padding: 80px 16px;
  }
  
  @media (max-width: 450px) {
    padding: 80px 16px;
  }
  
  &__footer {
    background: var(--grey-light);
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  
  &__header {
    z-index: 12;
    padding: 2%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    &-logo {
      display: inline-block;
      vertical-align: middle;
      width: 13%;
  
      @media (max-width: 620px) {
        width: 30%;
      }
      
      > img {
        width: 13%;
        height: auto;
  
        @media (max-width: 620px) {
          width: 30%;
        }
      }
    }
  }
  
  &__content {
    z-index: 0;
    width: 100%;
  }
}
</style>
