import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from "axios";
import router from "./router";
import VueHtmlToPaper from 'vue-html-to-paper';
import VueYandexMetrika from 'vue-yandex-metrika'
import * as Sentry from "@sentry/vue";
import {get_methods, set_methods, reference_list} from "./methods";

import Notifications from 'vue-notification'

const VUETIFY_RU_I18N = require('vuetify/es5/locale/ru')
const API_URL = process.env.VUE_APP_API_URL;
const API_CONTENT_URL = process.env.VUE_APP_CONTENT_API_URL;

const options = {
  name: '_blank',
  specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes'
  ],
  styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css',
      'rate-page.print.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

const tracesSampleRate = (() => {
  const env = process.env.VUE_APP_ENV_NAME;
  if (env === 'qa' || env === 'preprod') {
    return 1.0;
  } else if (env === 'production') {
    return 0.2;
  }
  return 0.0;
})();

Sentry.init({
  Vue,
  tracesSampleRate,
  dsn: "https://228624b48e271976cdbdb23531e7d349@sentry.vedexx.com//2",
  integrations: [Sentry.browserTracingIntegration({ router })],
  environment: process.env.VUE_APP_ENV_NAME,

  tracingOrigins: ['localhost', /^\//],

  beforeSend(event) {
    if (event.exception && event.exception.values) {
      const errorMessage = event.exception.values[0].value;

      const serverErrorPattern = /Request failed with status code 5\d{2}/;
      if (serverErrorPattern.test(errorMessage)) {
        return null;
      }
    }
    
    return event;
  },
});

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false

Vue.use(VueYandexMetrika, {
  id: 91141745,
  router: router,
  env: 'production'
})

Vue.use(Notifications)

Vue.$http = axios;
Vue.prototype.$appName = 'Dcl Vedexx App'
Vue.prototype.$serviceName = 'vedexx.com/rate-list-statistic'
//Vue.prototype.$address = "https://rest.vedexx.com/index.php";
Vue.prototype.$address = API_URL;
Vue.prototype.$api_content = API_CONTENT_URL;
//Vue.prototype.$localhost = "amrw://"
Vue.prototype.$key = ''
Vue.prototype.$getters = get_methods
Vue.prototype.$setters = set_methods
Vue.prototype.$references = reference_list;
Vue.prototype.$_storage = window.localStorage

Vue.prototype.$getapi = function (uri) {
  return axios
      .get(uri)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error;
      });
};

Vue.prototype.$frombase64 = function (b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;
  
  var byteCharacters = atob(b64Data);
  var byteArrays = [];
  
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    
    var byteArray = new Uint8Array(byteNumbers);
    
    byteArrays.push(byteArray);
  }
  
  var blob = new Blob(byteArrays, {
    type: contentType
  });
  return blob;
}

Vue.prototype.$savefile = function (obj) {
  var blob = this.$frombase64(obj.filebase64, obj.mime);
  var blobUrl = URL.createObjectURL(blob);
  var filelink = window.document.createElement('a');
  filelink.href = blobUrl
  filelink.download = obj.filename;
  document.body.appendChild(filelink);
  filelink.click();
  document.body.removeChild(filelink);
}

Vue.prototype.$postapi = function (uri, params, headers) {
  return axios
    .post(uri + "/", params, headers)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      throw error;
    });
};

Vue.prototype.$_setsetting = function (key, value) {
  Vue.prototype.$_storage.setItem(key, value)
}

Vue.prototype.$_getsetting = function (key) {
  return Vue.prototype.$_storage.getItem(key);
}

Vue.prototype.$_storclear = function () {
  Vue.prototype.$_storage.clear();
}

Vue.prototype.$_removesetting = function (key) {
  Vue.prototype.$_storage.removeItem(key);
}

Vue.prototype.$_mixed = function(mstring1, mstring2, mstring3) {
  return mstring3.substr(0, 5) +
      mstring2.substr(24) +
      mstring1.substr(5, 8) +
      mstring2.substr(8, 8) +
      mstring1.substr(21, 8) +
      mstring2.substr(0, 8) +
      mstring1.substr(13, 8) +
      mstring2.substr(16, 8) +
      mstring3.substr(5);
};

Vue.directive('phone', {
  bind(el) {
    el.addEventListener('input', function(e) {
      if (!e.isTrusted) {
        return;
      }

      let input = this;
      let value = input.value.replace(/\D/g, ''); // Удаляем все нецифровые символы
      let formattedValue = '';

      // Форматируем номер в зависимости от длины
      if (value.length > 0) {
        formattedValue += '+7 ';
      }
      if (value.length > 1) {
        formattedValue += '(' + value.slice(1, 4);
      }
      if (value.length >= 4) {
        formattedValue += ') ' + value.slice(4, 7);
      }
      if (value.length >= 7) {
        formattedValue += ' ' + value.slice(7, 9);
      }
      if (value.length >= 9) {
        formattedValue += ' ' + value.slice(9, 11);
      }

      input.value = formattedValue;

      // Устанавливаем курсор в конец строки
      const len = input.value.length;
      input.setSelectionRange(len, len);

      // Генерируем событие input для Vue.js
      input.dispatchEvent(new Event('input'));
    });
  },
});


new Vue({
  vuetify,
  render: h => h(App),
  lang: {
    locales: {
      ru: VUETIFY_RU_I18N.default
    },
    current: 'ru'
  },
  router
}).$mount('#app')
