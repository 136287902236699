<script>
export default {
  name: "AppButton",

  props: {
    label: {
      type: String,
      default: "Button",
    },

    type: {
      type: String,
      default: "submit",
    },

    size: {
      type: String,
      default: "medium",
      validator: (value) => ["small", "medium", "large"].includes(value)
    },

    color: {
      type: String,
      default: "orange",
      validator: (value) => ["orange", "blue", "secondary-blue", "ghost", "grey"].includes(value)
    },

    leftIcon: {
      type: String,
    },

    rightIcon: {
      type: String,
    },

    disabled: {
      type: Boolean,
    },

    infoBtn: {
      type: Boolean,
    },
  },

  data() {
    return {
      showInfo: false,
    };
  },

  methods: {
    showAdditionalInfo() {
      return this.showInfo = !this.showInfo
    },

    clickOutside() {
      this.showInfo = false
    }
  },
};
</script>

<template>
  <div class="btn" :class="`btn--${size}`">
    <button
      class="btn__main"
      :class="`btn__main--${color}`"
      :disabled="disabled"
      :type="type"
      @click="$emit('click')"
    >
    <div class="btn-icon">
      <img
        class="btn--left-icon"
        v-if="leftIcon"
        :src="leftIcon"
        alt="loop-icon"
      />
    </div>
      

      {{ label }}

      <img
        class="btn--right-icon"
        v-if="rightIcon"
        :src="rightIcon"
        alt="loop-icon"
      />
    </button>
    <button
      v-if="infoBtn"
      class="btn__additional"
      :class="`btn__additional--${color} ${
        showInfo ? 'btn__additional--active' : ''
      }`"
      :disabled="disabled"
      type="button"
      @click="showAdditionalInfo"
    >
      <span class="btn__info" v-if="showInfo" v-click-outside="clickOutside">
        <slot ></slot>
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.btn {
  display: flex;
  
  position: relative;
  width: 100%;

  &__main {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    padding: 0px 24px;
    border-radius: 16px;
    transition: 0.4s;
    width: 100%;
    cursor: pointer;

    @include font-description-semibold-0;

    &--orange {
      color: #fff;
      background: var(--orange-70);

      &:hover {
        background: var(--orange-50);
      }

      &:active {
        background: var(--orange-100);
      }
    }

    &--blue {
      color: #fff;
      background: var(--blue-70);

      &:hover {
        background: var(--blue-50);
      }

      &:active {
        background: var(--blue-100);
      }
    }

    &--secondary-blue {
      color: var(--grey-70);
      background: var(--blue-20);

      &:hover {
        background: var(--blue-10);
      }

      &:active {
        background: var(--blue-30);
      }
    }

    &--grey {
      color: var(--grey-70);
      background: var(--grey-20);

      &:hover {
        background: var(--grey-10);
      }

      &:active {
        background: var(--grey-20);
      }
    }

    &--ghost {
      color: var(--grey-60);
      background: transparent;
      border: 1px solid var(--grey-50);

      &:hover {
        border-color: var(--grey-60);
        color: var(--blue-70);
      }

      &:active {
        border-color: var(--grey-70);
        color: var(--blue-100) !important;
      }

      &:focus {
        color: var(--blue-70);
      }
    }

    > img {
      min-height: 16px;
      min-width: 16px;
    }
  }

  &--left-icon {
      padding-right: 8px;
    }

  &--right-icon {
    padding-left: 8px;
    }

  &--large {
    height: 48px;

    font-size: 16px;
    line-height: 20px;

    @media (max-width: $tablet-width) {
      font-size: 14px;
    }
  }

  &--medium {
    height: 40px;
  }

  &--small {
    height: 32px;
    padding: 0px 16px;
  }

  &__additional {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    padding-left: 12px;
    padding-right: 32px;
    transition: 0.4s;

    border-radius: 0 16px 16px 0;
    min-width: 16px;
    min-height: 16px;

    background-image: url("../../assets/img/icons/arrow-down-white.svg");
    background-repeat: no-repeat;
    background-position: center;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 2px;
      height: 16px;
    }

    &--active {
      background-image: url("../../assets/img/icons/arrow-up-white.webp");

      .btn__info {
        opacity: 1;
        visibility: visible;
        left: 0;
        margin-left: -240px;
        top: 50px;
        // min-height: fit-content;
        // min-width: fit-content;
        border-radius: 5px;
      }
    }

    &--orange {
      color: #fff;
      background-color: var(--orange-70);

      &:hover {
        background-color: var(--orange-50);
      }

      &:active {
        background-color: var(--orange-100);
      }

      &:before {
        border-left: 2px solid var(--orange-50);
      }
    }

    &--blue {
      color: #fff;
      background-color: var(--blue-70);

      &:hover {
        background-color: var(--blue-50);
      }

      &:active {
        background-color: var(--blue-100);
      }

      &:before {
        border-left: 2px solid var(--blue-50);
      }
    }

    &--secondary-blue {
      color: var(--grey-70);
      background-color: var(--blue-20);

      &:hover {
        background-color: var(--blue-10);
      }

      &:active {
        background-color: var(--blue-30);
      }

      &:before {
        border-left: 2px solid var(--blue-10);
      }
    }

    &--grey {
      color: var(--grey-70);
      background-color: var(--grey-20);

      &:hover {
        background-color: var(--grey-10);
      }

      &:active {
        background-color: var(--grey-20);
      }

      &:before {
        border-left: 2px solid var(--grey-10);
      }
    }

    &--ghost {
      color: var(--grey-60);
      background: transparent;
      border-right: 1px solid var(--grey-50);
      border-top: 1px solid var(--grey-50);
      border-bottom: 1px solid var(--grey-50);
      border-left: unset;

      &:hover {
        border-color: var(--grey-60);
        color: var(--blue-70);
      }

      &:active {
        border-color: var(--grey-70);
        color: var(--blue-100) !important;
      }

      &:focus {
        color: var(--blue-70);
      }

      &:before {
        border-left: 2px solid var(--grey-60);
      }
    }

    &:disabled {
      background-image: url("../../assets/img/icons/arrow-down-grey.svg") !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }

  &:has(
      .btn__additional--ghost,
      .btn__additional--grey,
      .btn__additional--secondary-blue
    )
    .btn__additional {
    background-image: url("../../assets/img/icons/arrow-down-black.svg");
    background-repeat: no-repeat;
    background-position: center;

    &--active {
      background-image: url("../../assets/img/icons/arrow-up-black.webp");
    }
  }

  &:has(.btn__additional) {
    .btn__main {
      border-radius: 16px 0 0 16px;
      padding: 0 8px 0 24px;

      &--ghost {
        border-left: 1px solid var(--grey-50);
        border-top: 1px solid var(--grey-50);
        border-bottom: 1px solid var(--grey-50);
        border-right: unset;
      }
    }
  }

  &__info {
    position: absolute;
    opacity: 0;
    margin-left: -250px;
    visibility: hidden;
    top: 200px;
    transition: 0.2s;
    min-width: fit-content;
    padding: 15px;
    z-index: 1;
  }

  &__main,
  &__additional {
    &:disabled {
      color: var(--grey-50);
      background: var(--grey-20);
      pointer-events: none;
      cursor: unset;

      &:focus {
        box-shadow: unset;
      }

      &:before {
        border-left: 2px solid var(--grey-50);
      }
      img {
        opacity: .6;
      }
    }
  }
}
</style>