<script>
import LayoutContent from "./LayoutContent";

export default {
  name: 'RatePageLayout',
  
  components: {
    LayoutContent
  },
  
};
</script>

<template>
  <div class="rate-layout ">
    <div class="g-container">
      <LayoutContent />
      <slot class="rate-layout__content"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rate-layout {
  background: var(--grey-10);
  width: 100%;
  height: 100%;
  margin: 0 auto 0;

  @media print {
    background: #FFFFFF;
  }
  
  @media (max-width: 425px) {
    padding: 40px 16px;
  }
  
  &__content {
    z-index: 10;
    position: absolute;
  }
}
</style>
