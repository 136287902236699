<script>
import ContactFooter from "./ContactFooter.vue";
import LayoutContent from "./LayoutContent";

export default {
  name: 'DefaultLayout',

  components: {
    LayoutContent,
    ContactFooter
  },
};
</script>

<template>
  <div>
  <div class="light-layout g-container">
    <div class="light-layout__header">
      <LayoutContent
        headerType="dark"
        :polygon="false"
      />
    </div>
    <slot class="light-layout__content g-container"/>
    
  </div >
  <ContactFooter v-if="this.$route.name !== 'Cabinet'"/>
</div>
</template>

<style lang="scss" scoped>

.light-layout {
  width: 100%;
  margin: 0 auto 0;
  padding-top: 100px;
  padding-bottom: 100px;

  //background: var(--main-bg-color);

  @media (max-width: 600px) {
    padding: 80px 16px;
  }
  
  &__header {
    z-index: 12;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--main-bg-color);
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    
    @media (max-width: 620px) {
      background: none;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    }
  }

  &__footer {
    background: var(--grey-light);
    position: absolute;
    height: 100px;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  &__content {
    z-index: 0;
    width: 100%;
  }
}
</style>
