import searchIcon from '../../assets/img/icons/menu-icons/loop.svg';
import vedexxIcon from '../../assets/img/icons/menu-icons/vedexx.svg';
import resourceIcon from '../../assets/img/icons/menu-icons/resource.svg';
import contactIcon from '../../assets/img/icons/menu-icons/contacts.svg';

export const menuList = [
  {
    title: 'Поиск ставки',
    link: 'searchRates',
    target: '_self',
    icon: searchIcon,
    name: 'search',
    children: []
  },
  
  {
    title: 'О платформе',
    link: '',
    icon: vedexxIcon,
    name: 'platform',
    children: [
      {
        title: 'Что такое Vedexx?',
        link: 'whatVedexxIs',
        target: '_self',
      },
      {
        title: 'Видеоинструкции',
        link: 'videoInstructions',
        target: '_self',
      },
      {
        title: 'Отзывы',
        link: 'reviews',
        target: '_self',
      },
    ]
  },
  
  {
    title: 'Ресурсы',
    link: '',
    icon: resourceIcon,
    name: 'resources',
    children: [
      {
        title: 'Блог',
        link: 'blog',
        target: '_blank',
      },
    ]
  },
  
  {
    title: 'Контакты',
    link: 'contact',
    target: '_self',
    icon: contactIcon,
    name: 'contacts',
    children: []
  }
]
