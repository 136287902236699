export const validateInn = (inn, error) => {
  var result = false;
  if (typeof inn === 'number') {
    inn = inn.toString();
  } else if (typeof inn !== 'string') {
    inn = '';
  }
  if (!inn.length) {
    error.code = 1;
    error.message = 'ИНН пуст';
  } else if (/[^0-9]/.test(inn)) {
    error.code = 2;
    error.message = 'ИНН может состоять только из цифр';
  } else if ([10, 12].indexOf(inn.length) === -1) {
    error.code = 3;
    error.message = 'ИНН может состоять только из 10 или 12 цифр';
  } else if (inn * 1 === 0) {
    error.code = 4;
    error.message = 'Неправильное контрольное число';
  }
  else {
    var checkDigit = function (inn, coefficients) {
      var n = 0;
      for (var i in coefficients) {
        n += coefficients[i] * inn[i];
      }
      return parseInt(n % 11 % 10);
    };
    switch (inn.length) {
      case 10:
        var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if (n10 === parseInt(inn[9])) {
          result = true;
        }
        break;
      case 12:
        var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
        if ((n11 === parseInt(inn[10])) && (n12 === parseInt(inn[11]))) {
          result = true;
        }
        break;
    }
    if (!result) {
      error.code = 4;
      error.message = 'Неправильное контрольное число';
    } else {
      error.code = null;
      error.message = null;
    }
  }
  return result;
}

// export const validateInn = (value, error) => {
//   var result = false
//
//   if(typeof value !== 'string' ||
//     (value.length !== 10 && value.length !== 12) ||
//     value.split('').some((symbol) => isNaN(Number(symbol)))
//   ) {
//     error.code = 3;
//     error.message = 'ИНН может состоять только из 10 или 12 цифр';
//   }
//
//   if(value.length === 10){
//     result = Number(value[9]) === (value.split('').slice(0, -1)
//         .reduce(
//           (summ, symbol, index) =>
//             [2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
//           0)
//       % 11) % 10;
//     if (!result) {
//       error.code = 4;
//       error.message = 'Неправильное контрольное число';
//     }
//   }
//   else if(value.length === 12){
//     let checkSumOne = (value.split('').slice(0, -2)
//         .reduce(
//           (summ, symbol, index) =>
//             [7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
//           0)
//       % 11 ) % 10;
//
//     let checkSumTwo = (value.split('').slice(0, -1)
//         .reduce(
//           (summ, symbol, index) =>
//             [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8][index] * Number(symbol) + summ,
//           0)
//       % 11 ) % 10;
//
//     result = (checkSumOne === Number(value[10]) && checkSumTwo === Number(value[11]));
//     if (!result) {
//       error.code = 4;
//       error.message = 'Неправильное контрольное число';
//     }
//   }
//   return result;
// }


export const validateBik = (bik, error) => {
  var result = false;
  if (typeof bik === 'number') {
    bik = bik.toString();
  } else if (typeof bik !== 'string') {
    bik = '';
  }
  if (!bik.length) {
    error.code = 1;
    error.message = 'БИК пуст';
  } else if (/[^0-9]/.test(bik)) {
    error.code = 2;
    error.message = 'БИК может состоять только из цифр';
  } else if (bik.length !== 9) {
    error.code = 3;
    error.message = 'БИК может состоять только из 9 цифр';
  } else {
    result = true;
  }
  return result;
}

export const validateRs = (rs, bik, error) => {
  var result = false;
  if (validateBik(bik, error)) {
    if (typeof rs === 'number') {
      rs = rs.toString();
    } else if (typeof rs !== 'string') {
      rs = '';
    }
    if (!rs.length) {
      error.code = 1;
      error.message = 'Р/С пуст';
    } else if (/[^0-9]/.test(rs)) {
      error.code = 2;
      error.message = 'Р/С может состоять только из цифр';
    } else if (rs.length !== 20) {
      error.code = 3;
      error.message = 'Р/С может состоять только из 20 цифр';
    } else {
      var bikRs = bik.toString().slice(-3) + rs;
      var checksum = 0;
      var coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
      for (var i in coefficients) {
        checksum += coefficients[i] * (bikRs[i] % 10);
      }
      if (checksum % 10 === 0) {
        result = true;
      } else {
        error.code = 4;
        error.message = 'Неправильное контрольное число';
      }
    }
  }
  return result;
}
